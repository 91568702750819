import React, { type FC } from 'react';
import classNames from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
// @ts-ignore
import { Pagination, Navigation } from 'swiper';

import styles from './slider.module.scss';
import type { Props } from './Slider.types';
import { Event } from '../Event';

import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './slider.scss';

export const Slider: FC<Props> = ({ events, lang, className, id }) => {
  return (
    <div id={id} className={classNames(styles.slider, className)}>
      {events && (
        <Swiper
          slidesPerView='auto'
          className={classNames('custom-swiper', styles.wrapper)}
          spaceBetween={30}
          modules={[Pagination, Navigation]}
          grabCursor
          pagination={{
            type: 'progressbar',
          }}
          navigation
        >
          {events.map((item, index) => {
            return (
              <SwiperSlide key={index}>
                <Event lang={lang} {...item} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      )}
    </div>
  );
};
