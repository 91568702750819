export const getUTCDate = (date: Date) => {
  const utcYear = date.getUTCFullYear();
  const utcMonth = date.getUTCMonth();
  const utcDate = date.getUTCDate();
  const utcHours = date.getUTCHours();
  const utcMinutes = date.getUTCMinutes();
  const utcSeconds = date.getUTCSeconds();

  const utc = new Date(
    Date.UTC(utcYear, utcMonth, utcDate, utcHours, utcMinutes, utcSeconds)
  ).getTime();

  return utc;
};
