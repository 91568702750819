import React, { type FC } from 'react';

import styles from './event.module.scss';
import type { Props } from './Event.types';
import { useTranslations } from '../../../i18n/utils';
import { getUTCDate } from '../../../utils';

export const Event: FC<Props> = ({
  img,
  lang,
  date,
  year,
  place,
  spanPlace,
  label,
  isAgree,
  width,
  finishedAt,
}) => {
  const t = useTranslations(lang);

  const currentDate = new Date();
  const currentUTC = getUTCDate(currentDate);

  return (
    <div className={styles.event}>
      <div className={styles.image}>
        <img style={{ width: `${width}%` }} src={img} />
      </div>
      <div className={styles.info}>
        {label && <div className={styles.label}>{t(label)}</div>}
        <p className={styles.date}>
          {date[lang]} {year && <span>{year}</span>}
        </p>
        <p className={styles.country}>
          {place[lang]} {spanPlace && <span>{spanPlace[lang]}</span>}
        </p>
        {isAgree && currentUTC < finishedAt && (
          <button className={styles.agree}>{t('events.agree')}</button>
        )}
      </div>
    </div>
  );
};
